<template>
  <div class="d-flex p-0 px-md-1">

    <!-- phone -->
    <div class='pms-absolut-left-btn' @click='smopen=!smopen' v-if='!smopen'>
      <button class='btn btn-sm'>{{lang.title}}</button>
    </div>
    <!-- phone -->

    <div class="flex-fill bg-white pms-schedule-listbox overflow-hidden shadow my-0 pt-1"
      :class='{"active": smopen}'>
      <!-- <div class="d-flex h-100"> -->
      <div class="flex-fill h-100 card shadow rounded position-relative">
        <div
          class="w-100 h-100 position-absolute bga-6 d-flex align-items-center justify-content-center"
          style="z-index: 2" v-if="changeLoading">
          <span class="text-white font-up-10">Loading...</span>
        </div>
        <!-- title -->
        <div class="card-header bg-dagee-blue p-1">
          <div class="d-flex align-items-center justify-content-between">
            <div>修改次數</div>
            <div class="flex-fill d-flex align-items-center justify-content-end">
              <button class="btn btn-sm btn-outline-secondary" @click="resetDayList()"
                v-if="changeDayListTemp.before.length > 0">
                <font-awesome-icon :icon="['fas', 'undo-alt']" size='lg' />
              </button>
              <button class="btn btn-sm btn-outline-secondary" @click="cancelResetDayList()"
                v-if="changeDayListTemp.after.length > 0">
                <font-awesome-icon :icon="['fas', 'redo-alt']" size='lg' />
              </button>

              <button class="ml-2 btn btn-sm btn-outline-success"
                @click="saveSchedule()">儲存</button>
            </div>

            <!-- <router-link class="text-white py-0 px-2" :to='{name:"index"}' tab="a">
              <font-awesome-icon :icon="['fas', 'angle-left']" size='lg' />
              {{lang.backpage}}
            </router-link> -->

            <!-- <div class="text-center">{{lang.title}}</div> -->

            <!-- <div class="d-none d-md-block cur-print" @click='openSearchBox()'>
                {{lang.searchlist}}
                <font-awesome-icon :icon="['fas', 'caret-right']" />
              </div> -->

            <!-- <div class="text-center" @click="smopen=!smopen">
              <button class="btn btn-sm py-0 px-2">
                <font-awesome-icon :icon="['fas', 'times']" size="lg" />
              </button>
            </div> -->
          </div>
        </div>
        <div class="d-flex test-height">
          <div class="d-flex flex-column flex-fill shadow-sm" v-if="tagSelect == 'schedule'">
            <div class="w-100 ">
              <div class="w-100 bg-white d-flex align-items-center justify-content-center p-2">
                <div class="flex-fill d-flex align-items-center justify-content-start ">
                  <!-- <i class="px-1" > -->
                  <font-awesome-icon :icon="['fas', 'edit']" size='lg' v-if='data.Edit'
                    class='cur-print text-danger mr-3' @click='EditScheduleInfo()' />
                  <!-- </i> -->
                  <!-- <i class="px-1"> -->
                  <font-awesome-icon :icon="['fas', 'copy']" size='lg'
                    class='cur-print text-danger mr-3' @click='CopySchedule(data.uid)' />
                  <!-- </i> -->
                  <!-- <i class="px-1">
                    <font-awesome-icon :icon="['fas', 'clipboard-list']" size='lg'
                      class='cur-print text-danger' @click='OpenPayList(data.uid)' />
                  </i>
                  <i class="px-1">
                    <font-awesome-icon :icon="['far', 'file-word']" size='lg'
                      class='cur-print text-danger' @click='tackQuote(data.uid)' />
                  </i> -->
                </div>

                <div class="d-flex align-items-center justify-content-center cur-print"
                  @click="autoset(data.uid)">
                  <font-awesome-icon :icon="['fas', 'map-marker-alt']" size="lg"
                    :class='{"text-muted":!autoSetLocation, "text-danger":autoSetLocation}' />
                  <span class="px-1"
                    :class='{"text-muted":!autoSetLocation, "text-danger":autoSetLocation}'>記錄</span>
                  <small class="text-warning">{{tmpdate}}</small>
                </div>
              </div>
              <div class="w-100 d-flex flex-wrap b-back-opacity p-2 text-white">
                <h4 class="w-100 font-up-2" :class='textcolor'>{{data.Title}}</h4>
                <div class="w-100 d-flex align-items-center justify-content-between">
                  <div class="flex-fill" style="font-size: 16px">
                    <span>{{data.StartDate | moment("MM/DD")}}
                      <small>({{data.StartDate | moment("dd")}})</small>
                    </span>
                    ~
                    <span>{{data.EndDate | moment("MM/DD")}}
                      <small>({{data.EndDate | moment("dd")}})</small>
                    </span>
                  </div>
                  <span>共
                    <small>{{data.EndDate | moment("diff",data.StartDate,"days") + 1}}</small>
                    {{lang.daytext}}
                  </span>
                  <button class="btn btn-sm" @click="AddDay(1)">
                    <font-awesome-icon :icon="['fas', 'plus']" size="lg"
                      class="cur-print text-white" />
                  </button>
                </div>

              </div>
            </div>
            <!-- <div class="card m-0">
              <div class="card-body p-0" :class='textcolor'>
                <div class="d-flex align-items-stretch">
                  <div class="d-flex flex-column flex-fill">
                    <div class="d-flex align-items-center justify-content-between p-2">
                      <div class="d-flex align-items-center">
                        <i class="px-1" v-if='data.Edit'>
                          <font-awesome-icon :icon="['fas', 'edit']" size='lg'
                            class='cur-print text-danger' @click='EditScheduleInfo()' />
                        </i>
                        <i class="px-1">
                          <font-awesome-icon :icon="['fas', 'copy']" size='lg'
                            class='cur-print text-danger' @click='CopySchedule(data.uid)' />
                        </i>
                        <i class="px-1">
                          <font-awesome-icon :icon="['fas', 'clipboard-list']" size='lg'
                            class='cur-print text-danger' @click='OpenPayList(data.uid)' />
                        </i>
                        <i class="px-1">
                          <font-awesome-icon :icon="['far', 'file-word']" size='lg'
                            class='cur-print text-danger' @click='tackQuote(data.uid)' />
                        </i>
                      </div>
                      
                      <div class="d-flex align-items-center justify-content-center cur-print"
                        @click="autoset(data.uid)">
                        <font-awesome-icon :icon="['fas', 'map-marker-alt']" size="lg"
                          :class='{"text-muted":!autoSetLocation, "text-danger":autoSetLocation}' />
                        <span class="px-1"
                          :class='{"text-muted":!autoSetLocation, "text-danger":autoSetLocation}'>記錄</span>
                        <small class="text-warning">{{tmpdate}}</small>
                      </div>
                    </div>

                    <div class="d-flex flex-column b-back-opacity p-2">
                      <div class="d-flex align-items-center justify-content-between">
                        <div>
                          <span>{{data.StartDate | moment("YYYY/MM/DD")}}
                            <small>({{data.StartDate | moment("dd")}})</small>
                          </span>
                          ~
                          <span>{{data.EndDate | moment("YYYY/MM/DD")}}
                            <small>({{data.EndDate | moment("dd")}})</small>
                          </span>
                        </div>
                        <span>共
                          <small>{{data.EndDate | moment("diff",data.StartDate,"days") + 1}}</small>
                          {{lang.daytext}}
                        </span>

                      </div>
                      <h4 class="font-up-2 flex-wrap" :class='textcolor'>{{data.Title}}</h4>
                    </div>
                  </div>
                  phone
                  <div
                class='pms-absolut-left-hide d-flex align-self-stretch align-items-center cur-print'
                v-if='smopen' @click='smopen=!smopen'>
                <div>
                  <font-awesome-icon class="font-up-2" :icon="['fas', 'caret-left']" />
                </div>
              </div>
                  phone
                </div>
              </div>
            </div> -->

            <div class="flex-fill d-flex flex-column p-2 overflow-auto can-not-select">

              <!-- <div class="d-flex align-items-center justify-content-center cur-print"
                style="border: 1px dashed black">
                <button class="w-100 btn btn-sm text-center" v-if='data.Edit' @click="AddDay(1)">
                  新增天數
                </button>

                <div class="w-100 text-center" v-if='!data.Edit'>
                    <a href="addDay" @click.prevent='CopySchedule(data.uid)'>複製行程</a>
                  </div>
              </div> -->

              <!-- <draggable :list="ScheduleList" class="d-flex flex-column cur-print"
                @end="ChangeDayAll()" :animation="draggableoption.animation"
                :delay='draggableoption.delay' ghost-class="pms-ghost" chosen-class="pms-chosen"> -->
              <draggable :list="ScheduleList" class="d-flex flex-column cur-print"
                @end="changeDayList()" :animation="draggableoption.animation"
                :delay='draggableoption.delay' ghost-class="pms-ghost" chosen-class="pms-chosen">

                <div class="b-day-box mb-2" v-for='(i, keys) in ScheduleList'
                  :key="'day__list__' + keys">
                  <div class="w-100">
                    <div class="w-100 d-flex align-items-center justify-content-between">
                      <div class="flex-fill d-flex align-items-end">
                        <b class="b-day-index font-up-0 letter-s-1 shadow-sm"
                          :class="{'active':i.subshow}" @click='dayshow(i)'>
                          第{{keys+1}}天
                        </b>

                        <b class="b-day-date px-2">
                          {{i.start | moment("YYYY/MM/DD")}}
                          週{{i.start | moment("dd")}}
                        </b>
                        <!-- <span class="text-danger ml-2">
                          共 {{i.sub.length}} 個點
                        </span> -->
                      </div>

                      <div class="d-flex align-items-center justify-content-between"
                        @click='StartTimeEdit(i)'>
                        <b class="btn-link">{{i.starttime}} 出發</b>
                        <!-- <font-awesome-icon class="ml-2" size="lg" :icon="['fas', 'edit']" /> -->
                      </div>



                      <!-- <div class="d-flex">
                        <span class="mx-2 text-danger cur-print" @click='DeleteDay(i)'
                          v-if="data.Edit">
                          <font-awesome-icon :icon="['fas', 'trash-alt']" />
                        </span>

                        <span class="b-day-date">{{i.start | moment("MM/DD")}}
                          <small>({{i.start | moment("dd")}})</small>
                        </span>
                      </div> -->
                    </div>

                    <div class="d-flex flex-column">
                      <div class="d-flex align-items-center justify-content-between">
                        <!-- <div class="d-flex align-items-center justify-content-between"
                          @click='StartTimeEdit(i)'>
                          <span> {{i.starttime}} 出發</span>
                          <font-awesome-icon class="ml-2" size="lg" :icon="['fas', 'edit']" />
                        </div> -->
                        <!-- <span class="text-center cur-print mr-4" @click='dayshow(i)'>
                          <font-awesome-icon :icon="['fas', (i.subshow) ? 'angle-down':'angle-up']"
                            size="2x" />
                        </span> -->
                        <div>
                          <!-- <div>
                            <button class="btn btn-sm btn-primary" @click="distanceCount(i)"
                              v-if="googleDistance">
                              距離
                            </button>
                          </div> -->
                        </div>
                        <!-- <div class="d-flex flex-fill flex-column align-items-end">
                          <div class="b-day-pricelist">
                            {{lang.text.nowprice}} {{i.nowprice | currency}}
                          </div>
                          <div class="b-day-pricelist">
                            {{lang.text.maxprice}} {{i.maxprice | currency}}
                          </div>
                        </div> -->
                      </div>

                    </div>
                  </div>

                  <!-- <b-collapse v-model="i.subshow"> -->
                    <draggable :list="i.sub" tag='ul' group="schedule" @end="changeDayList()"
                      :move="CheckMove" class='w-100 m-0'
                      :animation="draggableoption.animation" :delay='draggableoption.delay'
                      ghost-class='pms-ghost' chosen-class='pms-chosen'>
                      <!-- <draggable :list="i.sub" tag='ul' group="schedule" @end="ChangeDayAll()"
                      :move="CheckMove" class='w-100 shadow-sm px-2 m-0'
                      :animation="draggableoption.animation" :delay='draggableoption.delay'
                      ghost-class='pms-ghost' chosen-class='pms-chosen'> -->

                      <div class="d-flex flex-column b-schedule-box bg-white"
                        v-for='(j, index) in subShow(i)'
                        :key="'day__list__sub__' + keys + '-' + index "
                        @click='Markerclick(j.place_id,keys,index,j)'>

                        <div class="d-flex flex-column p-2"
                          :class="{'b-schedule-box-item-active':ClickMyPoint==(j.place_id+keys+index),'border-bottom':ClickMyPoint!=(j.place_id+keys+index)}">
                          <div class="d-flex border-bottom">
                            <div class="d-flex b-schedule-time">
                              <div class="b-schedule-time-box align-self-center">
                                <div class='b-schedule-up cur-print' v-if='index > 0'>
                                  <font-awesome-icon :icon="['fas', 'sort-up']" />
                                </div>

                                <div class='b-schedule-start'> {{j.starttime}}</div>
                                <div class='b-schedule-connect'> </div>
                                <div class='b-schedule-end'>{{j.endtime}}</div>

                                <div class='b-schedule-down cur-print'>
                                  <font-awesome-icon :icon="['fas', 'sort-down']"
                                    @click='DownChange(i,j,index)'
                                    v-if='index < i.sub.length - 1' />
                                </div>
                              </div>
                            </div>

                            <div class="d-flex flex-fill pl-2">
                              <div class="d-flex flex-fill flex-column b-schedule-data ">
                                <div class="d-flex text-center align-items-center">
                                  <div
                                    class="d-flex align-items-center justify-content-center rounded-circle"
                                    style="background: #A1D9EF; min-width:24px; min-height: 24px">
                                    <span class="text-white">{{index + 1}}</span>
                                  </div>
                                  <b class="text-left flex-fill flex-wrap px-2">{{j.title}}</b>

                                  <span class='text-danger cur-print' v-if="data.Edit"
                                    @click='EditPoint(keys,j,index)'>
                                    <font-awesome-icon :icon="['fas', 'edit']" />
                                  </span>
                                </div>
                                <div class="d-flex align-items-start justify-content-center py-2">
                                  <span v-if='j.tagMethod' class="flex-fill mr-2">
                                    <font-awesome-icon
                                      :icon="['fas', FilterIcon(j.tagMethod).icon]" />
                                    <span class="rounded-circle"
                                      v-for='(word, index) in j.maintypes' :key="index">
                                      {{lang.selectkid[word]}}
                                    </span>
                                  </span>
                                  <small>
                                    <font-awesome-icon :icon="['fas', 'map-marker-alt']" />
                                    {{lang.text.stop}}{{j.stoptime}}{{lang.text.min}}
                                  </small>
                                </div>

                                <div class="d-flex">
                                  <div class="flex-fill">
                                    <button class="b-day-btnlist">價格未定</button>
                                  </div>
                                </div>

                                <div class="d-flex justify-content-between">
                                  <div class='flex-fill'>
                                    <span class='ml-1 pms-text-dagee cur-print'
                                      v-for='(i, keys) in ((j.parallel.length < 9) ? j.parallel.length : 9)'
                                      :key="keys" v-if='j.parallel.length > 1'>
                                      <font-awesome-icon :icon="['far', 'clone']" size="lg" />
                                    </span>
                                    <span class='ml-1 pms-text-dagee cur-print'
                                      v-if='j.parallel.length > 9'>
                                      <font-awesome-icon :icon="['fas', 'plus']" size="lg" />
                                    </span>
                                  </div>
                                  <span class="text-danger cur-print" v-if="data.Edit"
                                    @click='DeleteSchedule(keys, j)'>
                                    <font-awesome-icon :icon="['fas', 'trash']" size="lg" />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="w-100 px-2">
                            <small v-if='index<i.sub.length-1'>
                              {{lang.text.distance}}{{j.distance}}{{lang.text.km}}
                              {{j.movetime}}{{lang.text.min}}</small>
                            <small v-if='j.movemethod=="setting"'>({{lang.text.setting}})</small>
                            <small v-if='index==i.sub.length-1'>
                              Last Point
                            </small>
                          </div>
                        </div>
                      </div>
                    </draggable>
                  <!-- </b-collapse> -->
                </div>
              </draggable>
            </div>


          </div>

          <div class="d-flex flex-fill shadow-sm" v-if="tagSelect == 'searchBox'"
            style="max-width: calc(100% - 2.5rem) !important;">
            <new-search-box :SearchData="SearchList" :AddSchedule="openAddBox"></new-search-box>

            <!-- <SearchdataBox :IsOpen.sync="IsSearchBox" :IsSearchHide.sync='IsSearchHide'
            :SearchData='SearchList' :SearchMarkClick="SearchMarkClick"
            :SearchSelected='SearchPlace' :AddSchedule="openAddBox" /> -->
          </div>



          <div class="pms-schedule-ctrlbar">
            <!-- <div class="pms-close text-center" @click="smopen=!smopen">
              <button class="btn btn-sm">
                <font-awesome-icon :icon="['fas', 'times']" size="lg" />
              </button>
            </div> -->
            <div class="h-100 pms-bookmark position-relative">
              <ul class="w-100">
                <li :class="{'active': tagSelect=='schedule'}" @click="tagChange('schedule')">
                  <a>行程內容</a>
                </li>
                <li :class="{'active': tagSelect=='searchBox'}" @click="tagChange('searchBox')">
                  <a>搜尋列表</a>
                </li>
              </ul>

              <div class="w-100 position-absolute pms-bookmark to-map" style="left:0; bottom:0">
                <ul class="w-100">
                  <li class='active' @click="smopen=!smopen">
                    <a>地圖</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="pms-schedule-ctrlbar">
        <div class="pms-close text-center" @click="smopen=!smopen">
          <button class="btn btn-sm">
            <font-awesome-icon :icon="['fas', 'times']" size="lg" />
          </button>
        </div>
        <div class="pms-bookmark">
          <ul class="w-100">
            <li :class="{'active': tagSelect=='schedule'}" @click="tagSelect = 'schedule'">
              <a>行程內容</a>
            </li>
            <li :class="{'active': tagSelect=='searchBox'}" @click="tagSelect = 'searchBox'">
              <a>搜尋列表</a>
            </li>
          </ul>
        </div>
      </div> -->
      <!-- </div> -->
    </div>
    <!-- 20200413 -->
    <div class="flex-fill d-flex flex-column position-relative" style="min-width: 50%">
      <!-- 控制 bar -->
      <!-- <div class="w-100 position-absolute" style="z-index:1001">
        <div class="w-100 pt-2 pb-1 bg-white pms-searchBox" :class="{'active': dropdownOpen}">
          <div class="w-100 d-flex justify-content-center pms-typesearch-box">
            <div class="pms-typesearch-item" v-for='(item, index) in quittype' :key="index"
              :class='{"active": item.name==quitdata.method}' @click='selectedquittype(item.name)'>
              <a href="#point" class="font-up-0" @click.prevent>
                <font-awesome-icon class="mr-1 mr-md-2" :icon="['fas', item.icon]" size="lg" />
                {{lang.text[item.name]}}
              </a>
            </div>
          </div>

          <div
            class="d-flex align-items-center justify-content-center justify-content-md-start p-0 p-md-1 py-2">
            <Searchbtn v-model="quitdata.searchtext" :search='SearchData'>
              <template #searchbtn='{searchbtn}'>
                <b-button-group size="sm" class='b-button-checked mr-0 '>
                  <b-button class='b-button-item text-nowrap'
                    :class="{'active':quitdata.searchtype=='Preferences'}"
                    @click='searchbtn("Preferences")'>
                    {{lang.text.Preferences}}</b-button>
                  <b-button class='b-button-item' :class="{'active':quitdata.searchtype=='google'}"
                    @click='searchbtn("google")'>{{lang.text.GoogleSearch}}</b-button>
                </b-button-group>
              </template>
            </Searchbtn>



            <div class='align-items-end col'>
            <input type="checkbox" id='autoreportGPS' v-icheck-style="autoreportGPS.isCheck"
            :value="true" :checked='autoreportGPS.isCheck.indexOf(true)>-1'>
            <label for="autoreportGPS" >{{lang.text.autoreportGPS}}</label>
          </div>
          </div>
        </div>

        <div class="w-100 mt-1 d-flex justify-content-center pms-dropdown-search">
          <font-awesome-icon :icon="['fas', 'chevron-circle-down']" size="2x"
            @click="dropdownOpen=!dropdownOpen" v-if="!dropdownOpen" />
          <font-awesome-icon :icon="['fas', 'chevron-circle-up']" size="2x"
            @click="dropdownOpen=!dropdownOpen" v-if="dropdownOpen" />
        </div>
      </div> -->

      <div class="w-100 h-100">
        <!-- <div class="pms-research-box" v-if='IsSearchBox'>
          <button class='pms-btn' @click='SearchData()'>{{lang.researcharea}}</button>
        </div> -->
        <div class='w-100 pms-map-box'>
          <!-- {{mapdata}} -->
          <MapView :zoom.sync='mapdata.zoom' :mapcenter.sync='mapdata.mapcenter'
            :SearchList="SearchListComputed" :SearchMarkClick="SearchMarkClick"
            :SearchSelected='SearchPlace' :AddSchedule="openAddBox" :Markerclick='Markerclick'
            :ClickMyPoint='ClickMyPoint' id='osmmap' :PointMethod="{EditPoint:EditPoint}"
            @openSearchBox="openSearchBox" :ref='"IMap"' />
        </div>
        <!--  -->
        <div class="w-100 d-flex align-items-center pms-map-list-schedule position-relative">
          <div
            class="w-100 h-100 position-absolute bga-6 d-flex align-items-center justify-content-center"
            style="z-index:2" v-if="changeLoading">
            <span class="text-white font-up-10">Loading...</span>
          </div>


          <div class="w-100 h-100 overflow-auto can-not-select">
            <draggable :list="ScheduleList" class='w-100 d-flex align-items-center'
              style="height: 4rem" @end="ChangeDayAll()" :move="CheckMove"
              :animation="draggableoption.animation" :delay='draggableoption.delay'
              :touch-start-threshold='4' ghost-class='pms-ghost' chosen-class='pms-chosen'>
              <div class="h-100 px-1 d-flex align-items-center cur-print"
                v-for="(i, keys) in ScheduleList" :key="keys">
                <div class="pms-map-item-day d-flex align-items-center px-3 rounded-pill"
                  :style="{background:dayColor[parseInt(keys % 5)]}" @click='i.subshow=!i.subshow'>
                  <b class="text-nowrap text-white">Day {{keys+1}}</b>
                </div>

                <!-- <div class="d-flex"> -->
                <draggable class='pms-map-item-point d-flex' :list="i.sub" group="schedule"
                  @end="ChangeDayAll()" :move="CheckMove" :animation="draggableoption.animation"
                  :delay='draggableoption.delay' :touch-start-threshold='4' ghost-class='pms-ghost'
                  chosen-class='pms-chosen' :style="{color:dayColor[parseInt(keys%5)]}">
                  <div class="d-flex align-items-center px-2 " v-for="(j, index) in i.sub"
                    :key="index" @click="Markerclick(j.place_id,keys,index,j)" v-if="i.subshow">
                    <div class="flex-fill d-flex flex-wrap align-items-center cur-print">
                      <div class="w-100 d-flex justify-content-center align-items-center">
                        <!-- <font-awesome-layers>
                          <font-awesome-icon :icon="['fas', 'map-marker']" size="lg" />
                          <font-awesome-layers-text class="text-white" :value="index + 1" />
                        </font-awesome-layers> -->
                        <div class="position-relative">
                          <b-icon class="fa-2x cur-print" icon="geo-alt"></b-icon>
                          <b class="w-100 position-absolute text-center rounded-circle bg-white d-flex align-items-center justify-content-center"
                            style="left: 5px; top:1px; height:20px; width:20px;max-height:20px; max-width:20px">
                            <span>{{index+1}}</span>
                          </b>
                        </div>
                      </div>
                      <div class="w-100 pms-map-item-point-text pms-text-nowrap "
                        style="font-size: 16px">{{j.title}}</div>
                    </div>
                    <!-- <div class="pms-map-item-point-connect mx-2">
                      <small>{{j.distance}}KM</small>
                      <div class="pms-map-hr"></div>
                      <small>{{Math.floor(j.movetime/60*100)/100}}H</small>
                    </div> -->
                  </div>
                </draggable>

                <!-- </div> -->
              </div>
            </draggable>
            <div class="w-100" style="height: 1rem"></div>
          </div>
        </div>
        <!-- <div class="w-100" style="min-height:1rem; background:rgba(161, 217, 239, 0.3)"></div> -->
        <!-- :class='["pms-day-color-"+(parseInt(keys%7)+1)]' -->

        <!-- <div class="w-100 text-right fixed-bottom" style="bottom: 16rem; z-index: 1000" v-if="!SearchList.lenght > 0">
          <button class=" btn btn-sm" @click="openSearchBox()" >
            <font-awesome-icon :icon="['fas', 'search-plus']" size="2x" v-if="!IsSearchBox" />
            <font-awesome-icon :icon="['fas', 'search-minus']" size="2x" v-if="IsSearchBox" />
          </button>
        </div> -->

        <div class="b-searchdata-box fixed-bottom" style="bottom: 0"
          :style="{'min-height': (IsSearchHide) ? '14rem':'0rem'}" v-show='IsSearchBox'>
          <LoadingBlock style="z-index:10000" :showLoading="loading"></LoadingBlock>

          <SearchdataBox style="z-index: 1040" :IsOpen.sync="IsSearchBox"
            :IsSearchHide.sync='IsSearchHide' :SearchData='SearchList'
            :SearchMarkClick="SearchMarkClick" :SearchSelected='SearchPlace'
            :AddSchedule="openAddBox" />
        </div>

      </div>
    </div>

    <!-- ========================================================================= -->
    <!-- ========================================================================= -->
    <!-- ========================================================================= -->
    <!-- ========================================================================= -->
    <!-- ========================================================================= -->

    <b-modal id='pms-point-info-add' :title='lang.EditStartTimeTitle' v-model="DayStartTime.show"
      :centered='true' @ok='EditStartTimeOk()' @hide='hideStartTime()'
      :footer-class="['bg-white','justify-content-center']">
      <template slot="default">

        <div class="row">
          <div class="col">
            <b-input-group size="sm" :append="lang.text.hour">
              <b-form-select v-model='DayStartTime.Startime.hour'>
                <option :value="number-1" v-for='number in 24'>{{number-1}}</option>
              </b-form-select>
            </b-input-group>
          </div>
          <div class="col">

            <b-input-group size="sm" :append="lang.text.min">
              <b-form-select v-model='DayStartTime.Startime.min'>
                <option :value="number-1" v-for='number in 60'>{{number-1}}</option>
              </b-form-select>
            </b-input-group>
          </div>
        </div>
      </template>
      <template tag='div' slot="modal-footer" slot-scope="{ ok, cancel, hide }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="md" variant="success" @click="ok()">
          <font-awesome-icon :icon="['fas', 'check']" />
        </b-button>
      </template>
    </b-modal>

    <!-- ========================================================================= -->
    <!-- ========================================================================= -->
    <!-- ========================================================================= -->
    <!-- ========================================================================= -->
    <!-- ========================================================================= -->
    <!-- :Secheduleinfo="Secheduleinfo" -->
    <EditPoint :pointdata="pointInfo" :show.sync='pointInfoShow'></EditPoint>

    <ComfirmBox :show.sync='Confirm.show' :context='Confirm.context' :title='Confirm.name'
      :okfunc='Confirm.okfunc'></ComfirmBox>

    <EditSchedulePage :Secheduleuid='Secheduleinfo.uid' :reback="EditReback"
      :show.sync="EditScheduleInfoShow.show"></EditSchedulePage>

    <PayList :show.sync='PayListbox.show' :Secheduleuid='Secheduleinfo.uid' :reback="PayListReback">
    </PayList>
  </div>
</template>

<script>
  // @ is an alias to /src
  import controler from "./EditSchedule";
  import "@/style/Edit.scss";
  export default controler

</script>
